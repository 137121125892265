// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React, { useState } from "react"
import {Link} from "gatsby"
import Logo from "../images/MABinc-logo.png"

const Navbar = () => {
    const [hovered, setHovered] = useState(false)
    const toggleHover = () => setHovered(!hovered)

    return (
  <nav className="navbar" role="navigation" aria-label="main navigation">
  <div className="navbar-brand">

      <Link to={"/"} className="navbar-item"><img src={Logo} /></Link>

    <a role="button" className={`navbar-burger burger ${hovered && 'is-active'} `} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={toggleHover}>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" className={`navbar-menu ${hovered && 'is-active'}`}>
    <div className="navbar-end">
        <Link to={"/direct-mail"} className="navbar-item">
            Direct Mail Services
        </Link>
        <Link to={"/about"} className="navbar-item">
            About Us
        </Link>
        <Link to={"/contact-us"} className="navbar-item">
            Contact Us
        </Link>
        <Link to={"/request-a-quote"} className="navbar-item">
            Request A Quote
        </Link>
    </div>
  </div>
</nav>
)}

export default Navbar
