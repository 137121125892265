import React from "react"
import Layout from "../components/layout"
import ContactEstimateForm from "../components/contactEstimateForm"
import ContactMap from "../components/map"

const ContactUs = ({location}) => {
  return (
    <Layout>
      <section className="hero is-medium has-bg-img">
        <div className="hero-body">
          <div className="container">
            <div className="has-text-centered">
              <span className="is-size-1 has-text-white has-text-weight-bold">
                Contact Us
              </span>
              <p className="is-size-4 has-text-white mb-1">
                Start your project today.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-5">
        <div className="contianer pt-5 px-5">
          <div className="columns mt-5 is-vcentered">
            <div className="column px-5">
              <ContactMap lat={47.4107725} lng={-122.2529673} zoom={13} controls={true} />
              <p className="is-size-3">Address</p>
              <p className="has-text-weight-bold">Mail Advertising Bureau, Inc.</p>
              <p>21319 68th Avenue South</p>
              <p>Kent, WA 98032</p>
              <p className="is-size-3">Phone Numbers</p>
              <p>Toll Free: <a href="tel:800-531-3122">800-531-3122</a></p>
              <p>Local: <a href="tel:253-872-3552">253-872-3552</a></p>
              <p>Fax: 800-887-6402</p>
            </div>
            <div className="column">
              <ContactEstimateForm location={location.pathname} href={location.href} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactUs
