import React, { useState } from "react"

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

export default function ContactEstimateForm({location, href}) {
  const [formData, setFormData] = useState(
    {
      name: '',
      email: '',
      message: '',
      submitMsg: '',
    }
  );

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
      body: encode({
        'form-name': form.getAttribute('name'),
        'subject': `Contact Form Submission from ${href}`,
        ...formData
      })
    })
    .then(() => setFormData({...formData, submitMsg: 'Thank you for contacting us, we will be in touch soon.'}))
    .catch((error) => alert(error))
  }
  
  return (
    <div className="form-box">
      <div>
        <h3 className="is-size-3 has-text-black mb-1 has-text-centered has-text-weight-bold">
          Contact Us
        </h3>
      </div>
      {!formData.submitMsg ? (
        <form className="" name={`contact-${location}`} data-netlify="true" onSubmit={handleSubmit} data-netlify-honeypot="bot-field">
        <div className="field is-hidden">
            <label className="label">Honeypot</label>
            <div className="control">
              <input className="input" type="text" name="bot-field" placeholder="Name" onChange={handleChange} />
            </div>
          </div>
          <div className="field">
            <label className="label">Name</label>
            <div className="control">
              <input className="input" type="text" name="name" placeholder="Name" onChange={handleChange} required />
            </div>
            {/* <p className="help is-danger">Name is required</p> */}
          </div>
          <div className="field">
            <label className="label">Email</label>
            <div className="control">
              <input
                className="input"
                name="email"
                type="email"
                placeholder="name@address.com"
                onChange={handleChange}
                required
              />
            </div>
            {/* <p className="help is-danger">This email is invalid</p> */}
          </div>
          <div className="field">
            <label className="label">Message</label>
            <div className="control">
              <textarea className="textarea has-fixed-size" name="message" placeholder="Tell us what you need" onChange={handleChange} required />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button className="button is-bittersweet is-fullwidth has-text-white" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div className="has-text-centered">{formData.submitMsg}</div>
      )}
    </div>
  )
}
