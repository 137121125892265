import React from "react"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Link } from "gatsby"
import Logo from "../images/MABinc-logo.png"

const Footer = () => (
  <section className="hero is-light">
    <div className="hero-body">
      <div className="container">
        <div className="columns">
          <div className="column is-one-quarter">
            <img src={Logo} />
          </div>
          <div className="column is-4 is-offset-6">
            <ul className="menu-list">
              <li>
                <Link to={"/direct-mail"}>Direct Mail Services</Link>
              </li>
              <li>
                <Link to={"/about"}>About Us</Link>
              </li>
              <li>
                <Link to={"/contact-us"}>Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Footer
