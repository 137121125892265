// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

import Navbar from "./navbar"

const Header = ({ siteTitle }) => (
  <header className="container">
    <Navbar />
  </header>
)


export default Header
